import React from 'react';
import { Input, Button, Upload, Space, message } from 'antd';
import { SendOutlined, PaperClipOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ReadyState } from 'react-use-websocket';

const { TextArea } = Input;

const MessageInput = ({ messageContent, setMessageContent, isProcessing, handleSendClick, readyState }) => {
    const { t } = useTranslation();

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendClick();
        }
    };

    const handleAttachmentChange = (info) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed`);
        }
    };

    const connectionStatusColor = () => {
        switch (readyState) {
            case ReadyState.OPEN:
                return 'green';
            case ReadyState.CONNECTING:
                return 'orange';
            case ReadyState.CLOSED:
            case ReadyState.UNINSTANTIATED:
            default:
                return 'red';
        }
    };

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <TextArea
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
                onKeyDown={handleKeyDown}
                rows={4}
                placeholder={t('TYPE_MESSAGE')}
                disabled={isProcessing}
            />
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                <Space>
                    <Upload onChange={handleAttachmentChange} showUploadList={false} className="interactive-agent-upload">
                        <Button icon={<PaperClipOutlined />}>{t('ATTACH_FILE')}</Button>
                    </Upload>
                    <Button type="primary" icon={<SendOutlined />} onClick={handleSendClick} disabled={isProcessing}>
                        {t('SEND')}
                    </Button>
                </Space>
                <Space className="connection-status">
                    <div className="status-indicator" style={{ backgroundColor: connectionStatusColor(), width: '10px', height: '10px', borderRadius: '50%' }} />
                    {t('CONNECTION_STATUS')}
                </Space>
            </Space>
        </Space>
    );
};

export default MessageInput;