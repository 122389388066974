import React from 'react';
import { Layout, Button, Space } from 'antd';
import '../styles/App.scss';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

const { Header: AntdHeader } = Layout;

const Header = ({ profile, login, logout }) => {
    const { t } = useTranslation();
    return (
        <AntdHeader className="header">
            <div className="logo-wrapper">
                <div className="logo">
                    <img className="logo-image" src={process.env.PUBLIC_URL + '/logo_omniskills.png'}
                         alt="OmniSkills Logo"/>
                </div>
            </div>
            <Space>
                {profile ? (
                    <div className="profile-container">
                        <img src={profile.picture} className="profile-picture" alt="AnalyseSkills"/>
                        <div className="title">{profile.given_name}</div>
                    </div>
                ) : ''}
                <LanguageSwitcher/>
                {profile ? (
                    <Button className="button" onClick={logout}>{t('log_out')}</Button>
                ) : (
                    <Button className="button" onClick={login}>{t('sign_in')}</Button>
                )}
            </Space>
        </AntdHeader>
    );
};

export default Header;