import React, { useState, useEffect, useCallback } from 'react';
import { Card, Button, message, Layout } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useWebSocket from 'react-use-websocket';
import config from '../config';
import CardContent from '../components/CardContent';
import MessageInput from '../components/MessageInput';
import '../styles/App.scss';

const { Content } = Layout;

const InteractiveAgent = ({ profile }) => {
    const { t } = useTranslation();
    const [currentCardIndex, setCurrentCardIndex] = useState(-1);
    const [messageContent, setMessageContent] = useState("");
    const [cards, setCards] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [loadingCards, setLoadingCards] = useState(new Set());
    const [expandedImages, setExpandedImages] = useState({});

    const handleWebSocketMessage = useCallback((parsedMessage) => {
        console.log("Received WebSocket message:", parsedMessage);

        setCards(prevCards => {
            const updatedCards = [...prevCards];
            const lastCardIndex = updatedCards.length - 1;

            if (!updatedCards[lastCardIndex]) {
                updatedCards[lastCardIndex] = { content: '' };
            }

            if (parsedMessage.message) {
                if (typeof parsedMessage.message === 'object' && parsedMessage.message.is_image) {
                    updatedCards[lastCardIndex].content += `\n![Image](${parsedMessage.message.url})`;
                } else if (typeof parsedMessage.message === 'string') {
                    // Check if the message is already in the content to avoid duplication
                    if (!updatedCards[lastCardIndex].content.includes(parsedMessage.message)) {
                        updatedCards[lastCardIndex].content += parsedMessage.message;
                    }
                }
            } else if (parsedMessage.url && parsedMessage.is_image) {
                updatedCards[lastCardIndex].content += `\n![Image](${parsedMessage.url})`;
            } else {
                console.warn("Unexpected message structure:", parsedMessage);
            }

            console.log("Updated cards:", updatedCards);
            return updatedCards;
        });

        if (parsedMessage.is_final || parsedMessage.type === 'end') {
            console.log("Message is final, stopping processing");
            setIsProcessing(false);
            setLoadingCards(prevLoadingCards => {
                const updatedLoadingCards = new Set(prevLoadingCards);
                updatedLoadingCards.delete(cards.length - 1);
                return updatedLoadingCards;
            });
        }
    }, [cards.length]);

    const { sendMessage, readyState } = useWebSocket(`${config.agentUrl}?user_id=${profile.id}`, {
        onOpen: () => console.log('WebSocket connection opened.'),
        onClose: () => {
            console.log('WebSocket connection closed.');
            setIsProcessing(false);
        },
        onError: (error) => {
            console.error('WebSocket error:', error);
            message.error('WebSocket connection error.');
            setIsProcessing(false);
        },
        shouldReconnect: () => true,
        onMessage: (event) => {
            const parsedMessage = JSON.parse(event.data);
            handleWebSocketMessage(parsedMessage);
        },
    });

    useEffect(() => {
        fetchResponses();
    }, [profile.id]);

    const fetchResponses = async () => {
        try {
            const response = await fetch(`${config.promptsUrl}?user_id=${profile.id}`);
            if (response.ok) {
                const data = await response.json();
                const formattedCards = data.results.map(formatCard);
                setCards(formattedCards);
                setCurrentCardIndex(-1);
            } else {
                message.error('Failed to load responses.');
            }
        } catch (error) {
            console.error('Error fetching responses:', error);
            message.error('Error fetching responses.');
        }
    };

    const formatCard = (result) => ({
        title: result.prompt,
        content: result.response,
        files: result.files || [],
        feedback: result.feedback,
        prompt_id: result.prompt_id
    });

    const handleSendClick = () => {
        if (messageContent.trim() && !isProcessing) {
            setIsProcessing(true);
            setCards(prevCards => [
                ...prevCards,
                { title: messageContent, content: '', files: [], feedback: 0, prompt_id: null }
            ]);
            setLoadingCards(prevLoadingCards => new Set(prevLoadingCards).add(cards.length));
            setCurrentCardIndex(cards.length);
            sendMessage(JSON.stringify({ prompt: messageContent, user_id: profile.id }));
            setMessageContent("");
        } else if (isProcessing) {
            message.error("Please wait for the current response.");
        } else {
            message.error("Message cannot be empty!");
        }
    };

    const handlePrevClick = () => {
        setCurrentCardIndex((prevIndex) => {
            if (prevIndex === -1) return cards.length - 1;
            if (prevIndex === 0) return -1;
            return prevIndex - 1;
        });
    };

    const handleNextClick = () => {
        setCurrentCardIndex((prevIndex) => {
            if (prevIndex === cards.length - 1) return -1;
            if (prevIndex === -1) return 0;
            return prevIndex + 1;
        });
    };

    const handleVote = async (promptId, like) => {
        try {
            await fetch(config.voteUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    prompt_id: promptId,
                    user_id: profile.id,
                    like: like ? 2 : 1,
                }),
            });

            setCards((prevCards) =>
                prevCards.map((card) =>
                    card.prompt_id === promptId ? { ...card, feedback: like ? 2 : 1 } : card
                )
            );
        } catch (error) {
            console.error('Error voting:', error);
            message.error('Error submitting vote.');
        }
    };

    const handleDelete = async (promptId) => {
        try {
            await fetch(config.deletePromptsUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ prompt_id: promptId }),
            });

            setCards((prevCards) => {
                const updatedCards = prevCards.filter((card) => card.prompt_id !== promptId);
                const newIndex = Math.min(currentCardIndex, updatedCards.length - 1);
                setCurrentCardIndex(newIndex >= 0 ? newIndex : -1);
                return updatedCards;
            });

            message.success('Prompt deleted successfully.');
        } catch (error) {
            console.error('Error deleting prompt:', error);
            message.error('Error deleting prompt.');
        }
    };

    const toggleImageExpansion = (imageSrc) => {
        setExpandedImages(prev => ({...prev, [imageSrc]: !prev[imageSrc]}));
    };

    const renderCard = () => {
        if (currentCardIndex === -1) {
            return (
                <div className="default-card-content">
                    <img src="/logo_omniskills_bw.png" alt="Logo" className="logo" />
                    <p>{t('HELLO')} <strong>{profile?.given_name || profile?.name || ""}</strong>.</p>
                    <p>{t('AMMA_DESCRIPTION')}</p>
                </div>
            );
        }
        const card = cards[currentCardIndex];
        return (
            <CardContent
                card={card}
                handleVote={handleVote}
                handleDelete={handleDelete}
                expandedImages={expandedImages}
                toggleImageExpansion={toggleImageExpansion}
                loadingCards={loadingCards}
                currentCardIndex={currentCardIndex}
            />
        );
    };

    return (
        <Layout className="interactive-agent-layout">
            <Content className="interactive-agent-content">
                <div className="card-container">
                    <div className="navigation-buttons">
                        <Button type="primary" icon={<LeftOutlined />} onClick={handlePrevClick} className="navigation-button" />
                        <Button type="primary" icon={<RightOutlined />} onClick={handleNextClick} className="navigation-button" />
                    </div>
                    <Card title={t('AMMA_TITLE')} className="interactive-agent-card">
                        {renderCard()}
                    </Card>
                </div>
                <div className="message-input-container">
                    <MessageInput
                        messageContent={messageContent}
                        setMessageContent={setMessageContent}
                        isProcessing={isProcessing}
                        handleSendClick={handleSendClick}
                        readyState={readyState}
                    />
                </div>
            </Content>
        </Layout>
    );
};

export default InteractiveAgent;