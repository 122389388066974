import React, { useState } from 'react';
import axios from 'axios';
import download from 'downloadjs';
import { Button, Upload, message, Space, Card, Spin, Dropdown, Menu, Divider, Modal, Form, Input, InputNumber } from 'antd';
import { UploadOutlined, CopyOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import config from "../config";
import { jsPDF } from 'jspdf';
import { devanagari } from '../fonts/devanagari-normal.js';

const { Dragger } = Upload;

const Transcribe = () => {
    const { t } = useTranslation();
    const [audioFileList, setAudioFileList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [mediaStream, setMediaStream] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileName, setFileName] = useState("");
    const [numSpeakers, setNumSpeakers] = useState(1);
    const [speakerNames, setSpeakerNames] = useState([]);

    const stripHtml = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || "";
    };

    const handleAudioChange = (info) => {
        setAudioFileList(info.fileList);
    };

    const handleAudioSubmit = async () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', audioFileList[0].originFileObj);
        try {
            const response = await axios.post(config.transcribe, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                timeout: 600000,
            });
            setApiResponse(response.data);
            message.success(t('file_submit_success'));
        } catch (error) {
            message.error(t('file_submit_error'));
        } finally {
            setIsLoading(false);
        }
    };

    const startRecording = async () => {
        setIsModalOpen(true);
    };

    const handleStartRecording = async () => {
        setIsModalOpen(false);
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setMediaStream(stream);
            const recorder = new MediaRecorder(stream);
            setMediaRecorder(recorder);
            const chunks = [];

            recorder.ondataavailable = (e) => {
                chunks.push(e.data);
            };

            recorder.onstart = () => {
                setIsRecording(true);
            };

            recorder.onstop = async () => {
                setIsRecording(false);

                const blob = new Blob(chunks, { type: 'audio/wav' });
                const formData = new FormData();
                formData.append('file', blob, fileName);
                formData.append('fileName', fileName);
                formData.append('numSpeakers', numSpeakers);
                formData.append('speakerNames', JSON.stringify(speakerNames));

                setIsLoading(true);
                try {
                    const response = await axios.post(config.transcribe, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        timeout: 600000,
                    });
                    setApiResponse(response.data);
                    message.success(t('file_submit_success'));

                    // Make the recorded file available for download
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                } catch (error) {
                    message.error(t('file_submit_error'));
                } finally {
                    setIsLoading(false);
                }
            };

            recorder.start();
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder && mediaStream) {
            mediaRecorder.stop();
            mediaStream.getTracks().forEach(track => track.stop());
            setMediaStream(null);
        }
    };

    const resetPage = () => {
        stopRecording();
        setAudioFileList([]);
        setApiResponse(null);
    };

    const makeCardTitle = (title, textToCopy) => (
        <Space className="card-header" justify="space-between">
            <span>{title}</span>
            <CopyToClipboard text={stripHtml(textToCopy)}>
                <Button size="small" type="text" icon={<CopyOutlined />}></Button>
            </CopyToClipboard>
        </Space>
    );

    const downloadFile = (type) => {
        switch (type) {
            case 'txt':
                downloadTxtFile();
                break;
            case 'pdf':
                downloadPdfFile();
                break;
            default:
                break;
        }
    };

    const downloadTxtFile = () => {
        const combinedText = `Summary:\n\n${apiResponse.summary}\n\nTranscription:\n\n${apiResponse.transcription}\n\nDiarize Transcription:\n\n${stripHtml(apiResponse.diarize_transcription)}`;

        const textBlob = new Blob([combinedText.replace(/(<([^>]+)>)/ig, "")], { type: 'text/plain;charset=utf-8' });
        download(textBlob, "transcript.txt");
    };

    const downloadPdfFile = () => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const margin = 12;
        const maxLineWidth = pageWidth - margin * 2;
        const lineHeight = 8;
        const sectionSpacing = 20;

        // Embed fonts
        doc.addFileToVFS('Devanagari.ttf', devanagari);
        doc.addFont('Devanagari.ttf', 'Devanagari', 'normal');


        const wrapText = (text, x, y) => {
            const lines = doc.splitTextToSize(text, maxLineWidth);
            lines.forEach(line => {
                if (y + lineHeight > pageHeight - margin) {
                    doc.addPage();
                    y = margin;
                }
                doc.text(line, x, y);
                y += lineHeight;
            });
            return y;
        };

        const addHeader = (title, x, y) => {
            doc.setFillColor(240, 240, 240); // Light grey background
            doc.rect(x - 2, y - 8, maxLineWidth + 4, 12, 'F'); // Background rectangle
            doc.setFontSize(16);
            doc.setFont("helvetica", "bold");
            doc.text(title, x, y);
            y += lineHeight + 2;
            doc.setFontSize(12);
            doc.setFont("Devanagari", "normal");
            return y;
        };

        let y = margin;

        if (apiResponse.summary) {
            y = addHeader("Summary", margin, y);
            y = wrapText(apiResponse.summary, margin, y);
            y += sectionSpacing;
        }

        if (apiResponse.transcription) {
            y = addHeader("Transcription", margin, y);
            y = wrapText(apiResponse.transcription, margin, y);
            y += sectionSpacing;
        }

        if (apiResponse.diarize_transcription) {
            y = addHeader("Diarize Transcription", margin, y);
            wrapText(stripHtml(apiResponse.diarize_transcription), margin, y);
        }

        doc.save("transcript.pdf");
    };

    const handleModalOk = () => {
        handleStartRecording();
    };

    const handleModalCancel = () => {
        setIsModalOpen(false);
    };

    const menu = (
        <Menu onClick={(e) => downloadFile(e.key)}>
            <Menu.Item key="txt" icon={<DownloadOutlined />}>
                {t('download_txt')}
            </Menu.Item>
            <Menu.Item key="pdf" icon={<DownloadOutlined />}>
                {t('download_pdf')}
            </Menu.Item>
        </Menu>
    );

    return (
        <Spin spinning={isLoading}>
            <div className="audio-container">
                <Card title={t('audio_to_text')}>
                    {apiResponse ? (
                        <>
                            <Card title={makeCardTitle(t('summary'), apiResponse.summary)} className="card summary-card">
                                <p className="summary">{apiResponse.summary}</p>
                            </Card>
                            <Card title={makeCardTitle(t('raw_transcript'), apiResponse.transcription)} className="card transcript-card">
                                <div className="transcription" dangerouslySetInnerHTML={{ __html: apiResponse.transcription }}></div>
                            </Card>
                            <Card title={makeCardTitle(t('transcript'), apiResponse.diarize_transcription)} className="card transcript-card">
                                <div className="transcription" dangerouslySetInnerHTML={{ __html: apiResponse.diarize_transcription }}></div>
                            </Card>
                            <Divider />
                            <Space direction="horizontal">
                                <Button type="primary" onClick={resetPage} >{t('reset')}</Button>
                                <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                                    <Button type="primary" icon={<DownloadOutlined />}>
                                        {t('download_as')}
                                    </Button>
                                </Dropdown>
                            </Space>
                        </>
                    ) : (
                        <Space direction="vertical" size="large" style={{ width: '50%' }}>
                            <Dragger
                                accept="audio/*,video/*"
                                beforeUpload={() => false}
                                onChange={handleAudioChange}
                                fileList={audioFileList}
                                style={{ width: '100%' }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                </p>
                                <p className="ant-upload-text">{t('audio_video_upload_text')}</p>
                            </Dragger>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                <Space direction="horizontal">
                                    <Button type="primary" onClick={handleAudioSubmit} disabled={audioFileList.length === 0} loading={isLoading}>
                                        {t('submit_audio')}
                                    </Button>
                                    <Button type="primary" onClick={startRecording} disabled={mediaStream || isRecording} loading={isLoading}>
                                        {isRecording ? <LoadingOutlined spin /> : t('start_recording')}
                                    </Button>
                                    {isRecording && (
                                        <Button type="primary" onClick={stopRecording} loading={isLoading}>
                                            {t('stop_recording')}
                                        </Button>
                                    )}
                                </Space>
                            </div>
                        </Space>
                    )}
                </Card>
            </div>
            <Modal
                title={t('recording_details')}
                open={isModalOpen}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okText={t('start_recording')}
            >
                <Form layout="vertical">
                    <Form.Item
                        name="fileName"
                        label={t('file_name')}
                        rules={[{ required: true, message: t('file_name_required') }]}
                    >
                        <Input value={fileName} onChange={(e) => setFileName(e.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name="numSpeakers"
                        label={t('number_of_speakers')}
                        rules={[{ required: true, message: t('num_speakers_required') }]}
                    >
                        <InputNumber min={1} max={10} value={numSpeakers} onChange={(value) => setNumSpeakers(value)} />
                    </Form.Item>
                    {Array.from({ length: numSpeakers }).map((_, i) => (
                        <Form.Item
                            key={i}
                            name={`speaker${i + 1}`}
                            label={`${t('speaker_name')} ${i + 1}`}
                            rules={[{ required: true, message: t('speaker_name_required') }]}
                        >
                            <Input
                                value={speakerNames[i] || ""}
                                onChange={(e) => {
                                    const newSpeakerNames = [...speakerNames];
                                    newSpeakerNames[i] = e.target.value;
                                    setSpeakerNames(newSpeakerNames);
                                }}
                            />
                        </Form.Item>
                    ))}
                </Form>
            </Modal>
        </Spin>
    );
};

export default Transcribe;
